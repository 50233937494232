import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import { googleConfig } from "./firebaseConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { auth } from "./firebaseConfig";

ReactDOM.render(
  <GoogleOAuthProvider auth={auth}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
