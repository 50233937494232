import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIB_API_KEY,
  authDomain: process.env.REACT_APP_FIB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIB_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Get a reference to the Auth service
const auth = getAuth(app);
export { auth };
