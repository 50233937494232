import React, { Fragment, Suspense, lazy } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { AuthProvider } from "./context/AuthContext";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { AppDataProvider } from "./context/AppDataContext";

// const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const Component = lazy(() => import("./components/Main"));

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <NotificationContainer />
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <AppDataProvider>
              <AuthProvider>
                <Switch>
                  {/* <Route path="/c">
                <LoggedInComponent />
              </Route> */}
                  <Route>
                    <Component />
                  </Route>
                </Switch>
              </AuthProvider>
            </AppDataProvider>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
