import React, { useState } from 'react';
import { createContext, useContext } from "react";

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
    const [appData, setAppData] = useState({})
  
    const setAppDetails = (data) => {
        setAppData(data);
      };

    return (
      <AppDataContext.Provider value={{appData, setAppDetails}}>
        {children}
      </AppDataContext.Provider>
    );
  };
  
  export const useAppData = () => useContext(AppDataContext); 